export const ROLES = {
  "AGENCY": "Agency",
  "BRAND": "Brand",
  "USER": "User",
}


export const isAdmin = (userData) => {
  return userData.is_superuser || userData.is_staff
}

export const isSuperAdmin = (userData) => {
  return userData.is_superuser
}

export const isAgency = (userData) => {
  return ["AGENCY"].includes(userData.user_type)
}
export const isBrand = (userData) => {
  return ["BRAND"].includes(userData.user_type)
}

export const isAventure = (userData) => {
  return userData.user_type === "AVENTURE"
}