import React from 'react'
import styles from './styles.module.scss'


const ErrorMessage = ({ message, style }) => (
  <div className={styles.form_message__error}>
    <p style={style}>{message} &nbsp;</p>
  </div>
)


export default ErrorMessage