import React from 'react'
import ErrorMessage from './ErrorMessage'
import classes from './styles.module.scss'



const InputField = ({ type, label, value, onChange, error, ...rest }) => {
  return (
    <div className={classes.inputContainer}>
      <label className={classes.inputContainer_label}>
        {label}
      </label>
      <input
        type={type || 'text'}
        className={classes.inputContainer_input}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      {!!error && (
        <ErrorMessage message={error} />
      )}
    </div>
  );
};

export default InputField