export const countries = [
  {
    label: "North America",
    options: [
      { label: "Canada", value: "CANADA" },
      { label: "United States", value: "UNITED_STATES" },
      { label: "Mexico", value: "MEXICO" },
      { label: "Brazil", value: "BRAZIL" },
    ]
  },
  {
    label: "Europe",
    options: [
      { label: "Spain", value: "SPAIN" },
      { label: "United Kingdom", value: "UNITED_KINGDOM" },
      { label: "France", value: "FRANCE" },
      { label: "Belgium", value: "BELGIUM" },
      { label: "Netherlands", value: "NETHERLANDS" },
      { label: "Germany", value: "GERMANY" },
      { label: "Italy", value: "ITALY" },
      { label: "Sweden", value: "SWEDEN" },
      { label: "Poland", value: "POLAND" },
      { label: "Egypt", value: "EGYPT" },
      { label: "Turkey", value: "TURKEY" },
      { label: "Saudi Arabia", value: "SAUDI_ARABIA" },
      { label: "United Arab Emirates", value: "UNITED_ARAB_EMIRATES" },
      { label: "India", value: "INDIA" },
    ]
  },
  {
    label: "North America",
    options: [
      { label: "Singapore", value: "SINGAPORE" },
      { label: "Australia", value: "AUSTRALIA" },
      { label: "Japan", value: "JAPAN" },
    ]
  }
]
