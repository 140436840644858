import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import classes from "../styles.module.scss";
import LayoutContainer from '../../../components/layouts/LayoutContainer';
import ConnectFormHeader from '../ConnectFormHeader';
import amazonIcon from "../../../assets/icons/amazon.png"
import Select from "react-select";
import currencyList from './currency.json';
import { sendAmazonSellerCentralRedirect } from '../../../store/actions/auth/amazonSellerCentralAuthActions';
import { useDispatch } from 'react-redux';

const AmazonSellerCentralRedirect = () => {
  const [currency, setCurrency] = useState('');
  const dispatch = useDispatch()

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search)
  const selling_partner_id = urlParams.get('selling_partner_id');
  const spapi_oauth_code = urlParams.get('spapi_oauth_code');
  const state = urlParams.get('state');


  const handleClick = () => {

    const data = {
      "selling_partner_id": selling_partner_id,
      "spapi_oauth_code": spapi_oauth_code,
      "state": state,
      "currency": currency
    }
    dispatch(sendAmazonSellerCentralRedirect(data))
  }

  return (
    <LayoutContainer>
      <div className={classes.connectForm}>
        <div className={classes.connectForm_box}>
          <ConnectFormHeader
            icon={amazonIcon}
            title="Amazon Seller Central"
            connected={false}
            revokeDisabled={true}
            connectDisabled={true}
          />
          <div className={classes.connectForm_form}>
            <div style={{ width: "100%" }}>
              <h3>Please Select Currency</h3>
              <Select
                options={currencyList}
                onChange={(e)=>setCurrency(e.value)}
              />
            </div>
          </div>

          <div
            className={classes.connectForm_buttons}
            style={{ marginTop: `50px` }}
          >
            <button
              disabled={false}
              type="submit"
              onClick={handleClick}
              className={classes.connectForm_intentwiseButton}
            >
              Save Changes
            </button>
          </div>

        </div>
      </div>
    </LayoutContainer>
  )
}

export default AmazonSellerCentralRedirect