import React from "react";
import ReactDateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import classes from "./styles.module.scss";

const maximumDate = new Date();

const DateSelectorSidebar = ({ open, onDateSelect, close }) => {
  return (
    <>
      <div
        className={classes.backDrop}
        onClick={(e) => {
          if (e.target.className !== classes.backDrop) {
            e.stopPropagation();
            return;
          }
          close();
        }}
      >
        <div className={classes.DateRangePicker}>
          <ReactDateRangePicker
            onSelect={onDateSelect}
            singleDateRange={true}
            className={classes.DateRangePicker_child}
            maximumDate={maximumDate}
          />
        </div>
      </div>
    </>
  );
};

export default DateSelectorSidebar;
