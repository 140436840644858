import React, { useState, useEffect } from "react";

import LayoutContainer from "../../components/layouts/LayoutContainer";
import image from "../../assets/images/settings_page_image.svg";
import darkImage from "../../assets/images/settings_page_image_dark.svg";
import classes from "./styles.module.scss";
import DataSourceConnect from "./DataSourceConnect";
import ChangePassword from "./ChangePassword";
import UpdateProfile from "./UpdateProfile";
import AccountAccess from "./AccountAccess/AccountAccess";

import { useSelector } from "react-redux";
import { setPageTitle } from "../../utils";
import { isAgency, isBrand } from "../../utils/authUtils";

const SettingsPage = ({ history }) => {
  const auth = useSelector(state => state.auth)
  const canAccess = isAgency(auth.user) || isBrand(auth.user)
  const [component, setComponent] = useState(canAccess ? "dataSource" : "profile");
  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  useEffect(() => {
    if (canAccess) {
      setComponent("dataSource")
    }
    else {
      setComponent("profile")
    }
    // eslint-disable-next-line
  }, [auth.user, canAccess])

  setPageTitle("Settings");

  return (
    <LayoutContainer>
      <div
        className={`${classes.setting} ${isNightMode && classes.setting_nightMode
          }`}
      >
        <div className={classes.setting_left}>
          <img
            src={isNightMode ? darkImage : image}
            className={classes.setting_image}
            alt="Date range"
          />
        </div>

        <div className={classes.setting_right}>
          <div className={classes.listWrap}>
            {canAccess && (
              <>
                <span
                  className={`${classes.listWrap_item} ${component === "dataSource" && classes.listWrap_item__active
                    }`}
                  onClick={() => setComponent("dataSource")}
                >
                  Data Source
                </span>
                <span
                  className={`${classes.listWrap_item} ${component === "accountAccess" && classes.listWrap_item__active
                    }`}
                  onClick={() => setComponent("accountAccess")}
                >
                  Access
                </span>
              </>
            )}

            <span
              className={`${classes.listWrap_item} ${component === "profile" && classes.listWrap_item__active
                }`}
              onClick={() => setComponent("profile")}
            >
              Profile
            </span>

            <span
              className={`${classes.listWrap_item} ${component === "changePassword" && classes.listWrap_item__active
                }`}
              onClick={() => setComponent("changePassword")}
            >
              Password
            </span>
          </div>
          {component === "dataSource" && <DataSourceConnect />}
          {component === "profile" && <UpdateProfile />}
          {component === "accountAccess" && <AccountAccess />}
          {component === "changePassword" && <ChangePassword />}
        </div>
      </div>
    </LayoutContainer>
  );
};

export default SettingsPage;
