import React from 'react'
import { BarLoader } from 'react-spinners'


const LoadingMessage = ({width, loadingComponent: Component, styles, ...rest}) => {

    return (
      <div
        style={{
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: rest.minHeight,
          ...styles
        }}
      >
        {Component ? <Component color="#4285F4" width={width} {...rest} /> : (
          <BarLoader color="#4285F4" height={4} width={width} {...rest} />
        )}
    </div>
    )

}


export default LoadingMessage