import * as Types from '../actionTypes';
import axios from "../../../utils/axios";
import { getHeaders } from '../../../utils';
import { loadUserInfo } from './authActions';


export const loadAmazonSellerCentralAuthStatus = (brandId) => dispatch => {
  dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: true})
  // remove previous loaded accounts
  dispatch({ type: Types.AMAZON_SELLER_CENTRAL_ACCOUNTS_LOADED, payload: null });
  dispatch({ type: Types.AMAZON_SELLER_CENTRAL_SAVE_CREDENTIALS_LOAD_ERROR, payload: {} });
  
  axios.get(`/amazon-seller-central/auth/${brandId}/status/`, {headers: getHeaders()})
  .then(res => {
    dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADED, payload: res.data})
    dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: false})
  })
  .catch(err => {
    dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: false})
  })
}


export const revokeAmazonSellerCentralAccess = (brandId) => dispatch => {
  dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: true})
  axios.get(`/amazon-seller-central/auth/${brandId}/revoke/`, {headers: getHeaders()})
  .then(res => {
    // console.log(res.data);
    dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADED, payload: res.data})
    dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: false})
    dispatch(loadAmazonSellerCentralAuthStatus(brandId))
    dispatch(loadUserInfo())
  })
  .catch(err => {
    // console.log(err.response);
    dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: false})
    dispatch(loadAmazonSellerCentralAuthStatus(brandId))
  })
}


export const fetchAmazonSellerCentralAuthUrl = (brandId,body) => dispatch => {
  dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: true})
  
  axios.post(`/amazon-seller-central/auth/${brandId}/auth-url/`,body,{headers: getHeaders()})
    .then(res=>{
      console.log(res.data.auth_url)
      const url = res.data.auth_url;
      window.open(url, "_self")
    })
    .catch(err=>
      // console.log(err.response);
      dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: false})  
    )
}

export const sendAmazonSellerCentralRedirect = (body) => dispatch => {
  axios.post(`amazon-seller-central/auth/redirect/`, body, {headers: getHeaders()})
  .then(res => {
    console.log(res);
  })
  .catch(err =>
    dispatch({type: Types.AMAZON_SELLER_CENTRAL_AUTH_STATUS_LOADING, payload: false})  
  )
}
