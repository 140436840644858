import { json2csvAsync } from "json-2-csv";
import moment from "moment";

export const exportNuggetCSV = async (jsonData) => {
	const chartInfo = jsonData.chart_info;
	const chartLabel = chartInfo.chart_label;
	const fileName = generateFileName(chartLabel, "NUGGET");

	try {
		const csv = await json2csvAsync(jsonData);
		return { csv, fileName };
	} catch (error) {
		throw Error(error);
	}
};

export const exportChartCSV = async (jsonData) => {
	const jsonDataCopy = JSON.parse(JSON.stringify(jsonData)); // copy the object
	const chartInfo = jsonDataCopy.chart_info;
	const chartLabel = chartInfo.chart_label;
	const fileName = generateFileName(chartLabel, "CHART");

	let chartData = jsonDataCopy.data;
	if (!(chartData instanceof Object && chartData instanceof Array)) {
		chartData = jsonDataCopy.data.data;
	}

	// remove color codes from data
	chartData = chartData.map((item) => {
		delete item["color"];
		return item;
	});
	// handle table charts data
	if (
		chartData.every(
			(item) => item instanceof Object && item instanceof Array
		)
	) {
		const replacer = (key, value) => (value === null ? "" : value); // handle null values here
		const header = Object.keys(chartData[0]);
		let csv = chartData.map((row) =>
			header
				.map((fieldName) => JSON.stringify(row[fieldName], replacer))
				.join(",")
		);
		csv = csv.join("\r\n");
		return { csv, fileName };
	} else {
		try {
			const csv = await json2csvAsync(chartData);
			return { csv, fileName };
		} catch (error) {
			throw Error(error);
		}
	}
};

const generateFileName = (chartTitle, type) => {
	const today = moment().format("YYYY_MM_DD__HHmmss");
	if (type === "NUGGET") {
		return `Nugget - ${chartTitle} - ${today}.csv`;
	} else {
		return `Chart - ${chartTitle} - ${today}.csv`;
	}
};

export const downloadZipFile = (filename, content) => {
	const element = document.createElement("a");
	element.href = "data:application/zip;base64," + encodeURI(content);
	element.target = "_blank";
	element.download = filename;
	element.click();
};

export const generateZipFileName = () => {
	const today = moment().format("YYYY_MM_DD__HHmmss");
	return `Hawthorn Dash - ${today}.zip`;
};
