import React from 'react'
import { useHistory } from 'react-router-dom';
import { SETTINGS_PAGE } from '../../Routes/urls';
import classes from "./styles.module.scss";
import backArrow from "../../assets/icons/arrow.png";


const ConnectFormHeader = ({
  icon, title, connected, revokeDisabled,
  connectDisabled, onRevokeClick = () => { },
  onConnectClick = () => { }
}) => {
  const history = useHistory()

  return (
    <div className={classes.connectForm_title}>
      <div className={classes.connectForm_title__left}>
        <div
          className={classes.connectForm_goBack}
          onClick={() => history.push(SETTINGS_PAGE)}
        >
          <img src={backArrow} alt="go back" />
        </div>
        <img
          src={icon}
          className={classes.connectForm_title__icon}
          alt={title}
        />
        <h2>{title}</h2>
      </div>

      <div className={classes.connectForm_title__right}>
        {connected ? (
          <button
            className={classes.connectForm_revoke}
            disabled={revokeDisabled}
            onClick={onRevokeClick}
          >
            Revoke
          </button>
        ) : (
          <>
            {!connectDisabled && (
              <button
                className={classes.connectForm_connectBtn}
                onClick={onConnectClick}
                disabled={connectDisabled}
              >
                Connect
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );

}

export default ConnectFormHeader
