import React from "react";
import classes from "./styles.module.scss";

const Footer = () => {
  return (
    <div className={classes.footer_container}>
      <div className={classes.footer_column}>
        <a href="https://www.mydash.ai/">
          <img
            className={classes.footer_logo}
            alt="logo"
            src="https://uploads-ssl.webflow.com/5eb05f4f6e90da9be00ef01f/5eb08c3bd637dce8ee7c34f2_Dash_Light.png"
          />
          <br />
        </a>
      </div>
      <div className={classes.footer_column}>
        <h3 className={classes.footer_header}>ABOUT US</h3>
        <div className={classes.footer_note}>
          Dash is a product-as-a-service company based out of Boston,
          Massachusetts. Contact us directly to learn more!
        </div>
        <div className={classes.footer_social}>
          <a
            href="https://www.facebook.com/pg/MyDash-107400424299129/"
            target="_blank"
            rel="noreferrer"
            className={classes.social_link}
          >
            <img
              src="https://uploads-ssl.webflow.com/5f583ae4b7c7918f6edc9f23/5f583ae4b7c791841fdca053_icons8-facebook-f-filled.svg"
              width="24"
              alt=""
              className={classes.top_nav_icon}
            />
          </a>
          <a
            href="https://twitter.com/AiMydash"
            target="_blank"
            rel="noreferrer"
            className={classes.social_link}
          >
            <img
              src="https://uploads-ssl.webflow.com/5f583ae4b7c7918f6edc9f23/5f583ae4b7c791a0f0dc9f97_icons8-twitter.svg"
              width="24"
              alt=""
              className={classes.top_nav_icon}
            />
          </a>
          <a
            href="https://linkedin.com/company/mydash/"
            target="_blank"
            rel="noreferrer"
            className={classes.social_link}
          >
            <img
              src="https://uploads-ssl.webflow.com/5f583ae4b7c7918f6edc9f23/5f583ae4b7c79101d4dc9f9b_icons8-linkedin-2.svg"
              width="24"
              alt=""
              className={classes.top_nav_icon}
            />
          </a>
        </div>
      </div>
      <div className={classes.footer_column}>
        <h3 className={classes.footer_header}>NAVIGATION</h3>
        <a href="https://www.mydash.ai/" className={classes.footer_menu_item}>
          Home
        </a>
        <a
          href="https://www.mydash.ai/service"
          className={classes.footer_menu_item}
        >
          Service
        </a>
        <a
          href="https://www.mydash.ai/agile-v-2-0"
          className={classes.footer_menu_item}
        >
          Agile
        </a>
        <a
          href="https://www.mydash.ai/expertise"
          className={classes.footer_menu_item}
        >
          Expertise
        </a>
        <a
          href="https://www.mydash.ai/partners"
          className={classes.footer_menu_item}
        >
          Partners
        </a>
        <a
          href="https://www.mydash.ai/pricing"
          className={classes.footer_menu_item}
        >
          Pricing
        </a>
      </div>
      <div className={classes.footer_column}>
        <h3 className={classes.footer_header}>CONTACT INFO</h3>
        <h4 className={classes.footer_subheader}>Main Offices</h4>
        <div className={classes.footer_note}>
          177 Huntington Ave Ste 1703 Boston, MA 022115
        </div>
        <h4 className={classes.footer_subheader}>Call Us</h4>
        <div className={classes.footer_note}>Office: +1 (617) 982 1344</div>
        <h4 className={classes.footer_subheader}>Email Us</h4>
        <div className={classes.footer_note}>Email: nick@mydash.ai</div>
      </div>
      <div className={`${classes.footer_note} ${classes.copywrights}`}>
        © 2021 Copyright PinchForth, Inc.
        <br />
        <a
          href="https://www.mydash.ai/terms-conitions"
          className={classes.footer_note_link}
        >
          Terms & Conditions
        </a>{" "}
        |{" "}
        <a
          href="https://www.mydash.ai/privacy"
          className={classes.footer_note_link}
        >
          Privacy
        </a>
      </div>
    </div>
  );
};

export default Footer;
