import pptxgen from 'pptxgenjs'
import moment from 'moment'
import { jsPDF } from "jspdf";
import JSZip from "jszip";
import {
  exportNuggetCSV,
  exportChartCSV,
  downloadZipFile,
  generateZipFileName,
} from "../../../utils/exportCsv";
import html2canvas from 'html2canvas'


export const download = (image, filename) => {
  const a = document.createElement('a')
  a.href = image
  a.download = filename
  a.click();
};


const captureScreen = async (node, preActions, postActions) => {
	if (!node) {
		throw new Error("You should provide correct html node.");
	}
	const scrollY = window.scrollY;
	preActions && preActions();

  try {
		const canvas = await html2canvas(node);
		const croppedCanvas = document.createElement("canvas");
		const croppedCanvasContext = croppedCanvas.getContext("2d");

		// init data
		const cropPositionTop = 0;
		const cropPositionLeft = 0;
		const cropWidth = canvas.width;
		const cropHeight = canvas.height;

		croppedCanvas.width = cropWidth;
		croppedCanvas.height = cropHeight;

		croppedCanvasContext.drawImage(
			canvas,
			cropPositionLeft,
			cropPositionTop
		);
		const base64Image = croppedCanvas.toDataURL("image/jpeg");
		window.scrollTo(0, scrollY);
		postActions && postActions();
		return { base64Image, canvas };
	} catch (error) {
    console.error(error)
		throw new Error(error);
	}
};


export const exportAsCSV = (setState, nuggetData, chartData) => {
  setState({ error: false, success: false, loading: true });
  const zip = new JSZip();
  const promises = [];

  const nuggetKeys = Object.keys(nuggetData);
  nuggetKeys.forEach((key) => {
    if (nuggetData[key].data.attribute_name) {
      promises.push(exportNuggetCSV(nuggetData[key].data));
    }
  });

  const chartKeys = Object.keys(chartData);

  chartKeys.forEach((key) => {
    if (
      chartData[key].data?.data?.length > 0 ||
      chartData[key].data?.data?.data?.length
    ) {
      promises.push(exportChartCSV(chartData[key].data));
    }
  });

  Promise.all(promises)
    .then((values) => {
      values.forEach(({ csv, fileName }) =>
        zip.file(fileName.replace(/[/]/g, " "), csv)
      );
      zip.generateAsync({ type: "base64" }).then(function (content) {
        downloadZipFile(generateZipFileName(), content);
        setState({ error: false, success: true, loading: false });
        setTimeout(() => {
          setState({ error: false, success: false, loading: false });
        }, 5000);
      });
    })
    .catch((error) => {
      setState({ error: true, success: false, loading: false });
      setTimeout(() => {
        setState({ error: false, success: false, loading: false });
      }, 5000);
    });
};


export const exportAsJPG = (node, setState, preActions, postActions, templateId) => {
  setState({
    error: false,
    success: false,
    loading: true,
  });
	captureScreen(node, preActions, postActions)
		.then(({ base64Image }) => {
			const filename = `template_${templateId}__${moment().format("YYYY_MM_DD")}.jpg`;
			download(base64Image, filename);
			setState({ error: false, success: true, loading: false });
		})
		.catch(() => {
			setState({
				error: true,
				success: false,
				loading: false
			});
		});
};


export const exportAsPDF = (node, setState, preActions, postActions, templateId) => {
  setState({
    error: false,
    success: false,
    loading: true,
  });
	captureScreen(node, preActions, postActions)
		.then(({ base64Image, canvas }) => {
			const filename = `template_${templateId}__${moment().format("YYYY_MM_DD")}`;
			const pdf = new jsPDF("", "mm", [canvas.width, canvas.height]);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
			pdf.addImage(base64Image, "jpg", 0, 0, pdfWidth, pdfHeight);
			pdf.save(`${filename}.pdf`);
			setState({
				error: false,
				success: true,
				loading: false,
			});
		})
		.catch(() => {
			setState({
				error: true,
				success: false,
				loading: false
			});
		});
};


export const exportAsPPT = (setState, preActions, postActions, templateId) => {
  setState({
    error: false,
    success: false,
    loading: true,
  });

	const pptx = new pptxgen();
	const promises = [];

  const pptRows = document.querySelectorAll('[id^="pptExportRow-"]')

	pptRows.forEach((node) => {
		const base64Image = captureScreen(node, preActions, postActions);
		promises.push(base64Image);
	});

	Promise.all(promises)
		.then((values) => {
			values.forEach((value) => {
				const slide = pptx.addSlide();
				slide.addImage({
					data: value.base64Image,
					x: 0.5,
					y: 0,
					w: 3.0,
					h: 1.6,
					sizing: { type: "contain", w: 9, h: 5.5 },
				});
			});

			pptx.writeFile({
				fileName: `dash__${moment().format("YYYY_MM_DD")}.pptx`,
			});
			setState({
				error: false,
				success: true,
				loading: false,
			});
		})
		.catch(() => {
			setState({
				error: true,
				success: false,
				loading: false,
			});
		});
};


export const exportTemplate = (options) => {
  const {
    exportType, node, setState,
    preActions, postActions,
    templateId, nuggetData, chartData
  } = options;

  if (exportType === "csv") {
    exportAsCSV(setState, nuggetData, chartData);
  }
  else {
    if (!node) {
      setState({ error: true, success: false, loading: false });
      return;
    }
    if (exportType === "pdf") {
      exportAsPDF(node, setState, preActions, postActions, templateId)
    }
    else if (exportType === 'jpg'){
      exportAsJPG(node, setState, preActions, postActions, templateId)
    }
    else  if (exportType === "ppt") {
      exportAsPPT(setState, preActions, postActions, templateId)
    }
  }
};