import axios from "../../../utils/axios";
import { getHeaders } from "../../../utils";


const services = {
    loadUsers: () => {
      const url = "/auth/company/users/";
      return axios({ method: 'get', url: url, headers: getHeaders() });
    },
    createUser: (data) => {
      const url = "/auth/company/users/";
      return axios({ method: 'post', url: url, data: data, headers: getHeaders() });
    },
    editUser: (userId, data) => {
      const url = `/auth/company/users/${userId}/`;
      return axios({ method: 'patch', url: url, data: data, headers: getHeaders() });
    },
    deleteUser: (userId) => {
      const url = `/auth/company/users/${userId}/`;
      return axios({ method: 'delete', url: url, headers: getHeaders() });
    }
};

export default services;
