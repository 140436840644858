import React, { useState } from "react";
import classes from "./styles.module.scss";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../../assets/images/sidebar/pdf.svg";
import pdfNightIcon from "../../../assets/images/sidebar/pdfNight.svg";
import csvIcon from "../../../assets/images/sidebar/csv.svg";
import csvNightIcon from "../../../assets/images/sidebar/csvNight.svg";
import jpgIcon from "../../../assets/images/sidebar/jpg.svg";
import jpgNightIcon from "../../../assets/images/sidebar/jpgNight.svg";
import pptIcon from "../../../assets/images/sidebar/ppt.svg";
import pptNightIcon from "../../../assets/images/sidebar/pptNight.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import LoadingMessage from "../../helpers/LoadingMessage";
import { DotLoader } from "react-spinners";
import { exportTemplate } from "./exportHelpers";

const ExportDialog = ({ open, close }) => {
  const template = useSelector((state) => state.template);
  const nuggetData = useSelector((state) => state.nuggetData);
  const chartData = useSelector((state) => state.chartData);
  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [exportStatus, setExportStatus] = useState({
    error: false,
    success: false,
    loading: false,
  });

  const supportedFormats = [
    {
      value: "jpg",
      display: "JPG",
      icon: isNightMode ? jpgNightIcon : jpgIcon,
    },
    {
      value: "csv",
      display: "CSV",
      icon: isNightMode ? csvNightIcon : csvIcon,
    },
    {
      value: "pdf",
      display: "PDF",
      icon: isNightMode ? pdfNightIcon : pdfIcon,
    },
    {
      value: "ppt",
      display: "PPT",
      icon: isNightMode ? pptNightIcon : pptIcon,
    },
  ];

  const screenshotPreActions = () => {
    const svgContainer = document.getElementById("funnelChart");
    if (svgContainer) {
      const svg = svgContainer.getElementsByTagName("svg")[0];
      svg.setAttribute("transform", "");
      svgContainer.style.transform = "rotate(-90deg)";
    }
    setScrollPosition(window.scrollY);
    window.scrollTo(0, 0);
  };

  const screenshotPostActions = () => {
    const svgContainer = document.getElementById("funnelChart");
    if (svgContainer) {
      const svg = svgContainer.getElementsByTagName("svg")[0];
      svg.setAttribute("transform", "rotate(270)");
      svgContainer.style.transform = "";
    }
    window.scrollTo(0, scrollPosition);
  };


  return (
    <div
      className={classes.backDrop}
      onClick={(e) => {
        if (e.target.className !== classes.backDrop) {
          e.stopPropagation();
          return;
        }
        close();
      }}
    >
      <div
        className={`${classes.exportDialog} ${isNightMode && classes.exportDialog_nightMode
          }`}
      >
        <h3>Export Data</h3>
        {exportStatus.loading ? (
          <div className={classes.exportDialog_iconContainer}>
            <div>
              <LoadingMessage loadingComponent={DotLoader} size={60} />
              <div>
                Please wait while
                <br />
                we're getting the data ready!
              </div>
            </div>
          </div>
        ) : (
          <>
            {
              exportStatus.error ||
              exportStatus.success ? (
              <>
                {exportStatus.error ? (
                  <div className={classes.exportDialog_iconContainer}>
                    <div>
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        size="4x"
                        color="#f76277"
                      />
                      <div>
                        Sorry! We're getting trouble
                        <br />
                        while exporting the data.
                        <br />
                        Please try again later.
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classes.exportDialog_iconContainer}>
                    <div>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="4x"
                        color="#2dce98"
                      />
                      <div>Data exported successfully!</div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {supportedFormats.map((format) => (
                  <div
                    key={format.value}
                    className={classes.exportDialog_iconContainer}
                  >
                    <div
                      onClick={() => exportTemplate({
                        exportType: format.value,
                        node: document.getElementById("dashboard"),
                        setState: setExportStatus,
                        preActions: screenshotPreActions,
                        postActions: screenshotPostActions,
                        templateId: template.activeTemplate,
                        nuggetData,
                        chartData
                      })}
                      className={classes.exportDialog_iconContainer__item}
                    >
                      <img src={format.icon} alt={format.value} />
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ExportDialog;
