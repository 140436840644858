import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  connected: false,
  account_name: "",
  accounts_list: null,
  credentials: {
    loading: false,
    success: false,
    data: {},
    errors: {},
  },
};

function intentwiseReducer(state = initialState, action) {
  switch (action.type) {
    case Types.INTENTWISE_AUTH_STATUS_LOADED: {
      return {
        ...state,
        connected: action.payload.connected,
        account_name: action.payload.account_name,
        errors: {},
      };
    }
    case Types.INTENTWISE_AUTH_STATUS_LOADING: {
      return {
        ...state,
        loading: action.payload,
        errors: {},
      };
    }
    case Types.INTENTWISE_AUTH_STATUS_LOAD_ERROR: {
      return {
        ...state,
        errors: action.payload,
      };
    }
    case Types.INTENTWISE_CREDENTIALS_LOADING: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          loading: action.payload
        }
      };
    }
    case Types.INTENTWISE_CREDENTIALS_LOADED: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          data: action.payload,
        }
      };
    }
    case Types.INTENTWISE_SAVE_CREDENTIALS_LOADING: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          loading: action.payload
        }
      };
    }
    case Types.INTENTWISE_SAVE_CREDENTIALS_LOADED: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          data: action.payload,
          errors: {}
        }
      };
    }
    case Types.INTENTWISE_SAVE_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          success: action.payload
        }
      };
    }
    case Types.INTENTWISE_SAVE_CREDENTIALS_LOAD_ERROR: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          success: false,
          errors: action.payload
        }
      };
    }
    case Types.INTENTWISE_ACCOUNTS_LOADED: {
      return {
        ...state,
        accounts_list: action.payload,
      };
    }

    default:
      return state;
  }
}

export default intentwiseReducer;
