import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import { useSelector, useDispatch } from 'react-redux'
import { updateProfile } from "../../store/actions/auth/authActions";
import { PROFILE_UPDATE_ERRORS } from "../../store/actions/actionTypes";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import InputField from '../../components/FormElements/InputField'


const UpdateProfile = () => {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  // states
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [errors, setErrors] = useState({})

  const submitHandler = e => {
    e.preventDefault()
    dispatch(updateProfile({ username, email, name, phone }))
  }

  useEffect(() => {
    if (auth.user.username) setUsername(auth.user.username)
    if (auth.user.email) setEmail(auth.user.email)
    if (auth.user.first_name) setName(auth.user.first_name + " " + auth.user.last_name)
    if (auth.user.phone) setPhone(auth.user.phone)
    // eslint-disable-next-line
  }, [auth.user])

  useEffect(() => {
    dispatch({ type: PROFILE_UPDATE_ERRORS, payload: {} })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (phone) {
      if (isValidPhoneNumber(phone)) {
        setErrors({
          ...errors,
          phone: ""
        })
      } else {
        setErrors({
          ...errors,
          phone: 'Invalid phone number'
        })
      }
    }

    // eslint-disable-next-line
  }, [phone])

  return (
    <div className={classes.setting_connectForm}>
      <h2 className={classes.setting_connectForm__header}>
        Update Profile
      </h2>
      <form onSubmit={submitHandler}>
        <InputField
          label="Email"
          type="email"
          value={email}
          onChange={setEmail}
          error={auth.updateProfile.errors.email}
        />
        <InputField
          label="Username"
          value={username}
          onChange={setUsername}
          error={auth.updateProfile.errors.username}
        />
        <InputField
          label="Full Name"
          value={name}
          onChange={setName}
          error={auth.updateProfile.errors.name}
        />

        <div className={classes.inputContainer}>
          <label className={classes.inputContainer_label}>
            Phone
          </label>
          <PhoneInput
            className={classes.inputContainer_input}
            value={phone}
            onChange={setPhone}
            required
            countryCallingCodeEditable={true}
            international
          />
          {auth.updateProfile.errors.phone && (
            <div className={classes.inputContainer_errorMessage}>{auth.updateProfile.errors.phone[0]}</div>
          )}
          {errors.phone && (
            <div className={classes.inputContainer_errorMessage}>{errors.phone}</div>
          )}
        </div>

        {auth.updateProfile.success && (
          <div className={classes.inputContainer}>
            <div className={classes.inputContainer_successMessage}>
              Profile informations saved successfully!
            </div>
          </div>
        )}

        <button
          disabled={auth.loading || !Object.values(errors).every(x => (x === null || x === '' || x === undefined))}
          className={classes.inputContainer_submitBtn}
          type='submit'
        >
          {auth.loading ? "Please wait..." : "Save"}
        </button>
      </form>
    </div>
  )

}

export default UpdateProfile
