import React, { useState } from 'react'
import InputField from "../../../components/FormElements/InputField";
import { ROLES } from "../../../utils/authUtils";
import classes from "../styles.module.scss";
import apiServices from "./apiServices";
import Select from "react-select";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../components/FormElements/ErrorMessage";

const UserCreationForm = ({ onSuccess }) => {
  const user_brands = useSelector((state) => state?.auth?.user?.brands);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [full_name, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [user_type, setUserType] = useState("");
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  console.log(brands);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    setMessage("");

    apiServices
      .createUser({
        username: username,
        email: email,
        full_name: full_name,
        password: password,
        country: country,
        user_type: user_type,
        brands,
      })
      .then((res) => {
        setLoading(false);
        setMessage(res.data?.message);
        onSuccess();
      })
      .catch((error) => {
        setLoading(false);
        setErrors(error.response?.data || {});
      });
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <form onSubmit={submitHandler}>
        <InputField
          label="Email"
          type="email"
          value={email}
          onChange={setEmail}
          error={errors.email}
        />
        <InputField
          label="Username"
          value={username}
          onChange={setUsername}
          error={errors.username}
        />
        <InputField
          label="Password"
          value={password}
          onChange={setPassword}
          error={errors.password}
        />

        <InputField
          label="Full Name"
          value={full_name}
          onChange={setFullName}
          error={errors.full_name}
        />

        <InputField
          label="Country"
          value={country}
          onChange={setCountry}
          error={errors.country}
        />

        <div className={classes.inputWrapper}>
          <label htmlFor="role">Select Role</label>
          <Select
            defaultValue={user_type}
            placeholder="Select Role"
            id="role"
            onChange={(value) => setUserType(value.value)}
            options={Object.keys(ROLES).map((roleKey) => ({
              value: roleKey,
              label: ROLES[roleKey],
            }))}
          />
          {errors?.user_type && <ErrorMessage message={errors.user_type} />}
        </div>

        <div className={classes.inputWrapper}>
          <label htmlFor="role">Select Brand(s)</label>
          <Select
            defaultValue={brands}
            isMulti
            placeholder="Select Brand(s)"
            onChange={(values) => setBrands(values.map((value) => value.value))}
            options={user_brands?.map((brand) => ({
              value: brand.id,
              label: brand.name,
            }))}
          />
          {errors?.brands && <ErrorMessage message={errors.brands} />}
        </div>

        {message && (
          <div
            style={{ color: "green", fontWeight: "bold", marginBottom: "10px" }}
          >
            {message}
          </div>
        )}

        <button
          disabled={loading}
          className={classes.inputContainer_submitBtn}
          type="submit"
        >
          {loading ? "Please wait..." : "Create User"}
        </button>
      </form>
    </div>
  );
};


export default UserCreationForm