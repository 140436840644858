import * as Types from "../actions/actionTypes";

const initialState = {
	start: "",
	end: "",
};

function dateRangeReducer(state = initialState, action) {
	switch (action.type) {
		case Types.SET_DATE_RANGE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: return state;
	}
}

export default dateRangeReducer;
