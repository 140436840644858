import * as Types from "../actions/actionTypes";


const initialState = {
  templates: [],
  activeTemplate: "1",
  activeBrand: "1",
};

function templateReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: action.payload,
      };
    }
    case Types.SET_ACTIVE_BRAND: {
      return {
        ...state,
        activeBrand: action.payload,
      };
    }
    case Types.USER_LOGGED_IN: {
      const templates = action.payload
        ? action.payload?.company?.templates
        : state.templates;
      return {
        ...state,
        templates: templates,
      };
    }
    default:
      return state;
  }
}

export default templateReducer;
