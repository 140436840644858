import React, { useEffect, useState } from "react";
import classes from "../styles.module.scss";
import UserCreationForm from "./UserCreationForm";
import editIcon from "../../../assets/icons/edit.png";
import deleteIcon from "../../../assets/icons/delete.png";
import ErrorMessage from "../../../components/FormElements/ErrorMessage";
import apiServices from './apiServices'
import LoadingMessage from "../../../components/helpers/LoadingMessage";
import UserRoleUpdate from "./UserRoleForm/UserRoleUpdate";
import { ROLES } from "../../../utils/authUtils";


const AccountAccess = () => {

  const [isCreateForm, setIsCreateForm] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [editingUserObj, setEditingUserObj] = useState({})
  const [isEditingUser, setIsEditingUser] = useState(false)

  const handleDeleteUser = (userId) => {

    const confirmed = window.confirm("Are you sure?")
    if (confirmed) {
      setLoading(true)
      setError("")

      apiServices.deleteUser(userId)
        .then(res => {
          setLoading(false)
          setUsers(users.filter(u => u.id !== userId))
        })
        .catch(error => {
          setLoading(false)
          setError(error.response?.data?.message || "Something went wrong.")
        })
    }
  }

  const handleEditUser = (userObj) => {
    setIsEditingUser(true)
    setEditingUserObj(userObj)
  }

  const loadUsers = () => {
    setError("")
    setLoading(true)
    apiServices.loadUsers()
      .then(res => {
        setLoading(false)
        setUsers(res.data)
      })
      .catch(error => {
        setLoading(false)
        setError(error.response?.data?.message || "Something went wrong.")
      })
  }

  useEffect(() => {
    loadUsers()
  }, [])

  return (
    <div className={classes.setting_connectForm}>
      <h2 className={classes.setting_connectForm__header}>Account Access</h2>

      {error && (
        <ErrorMessage
          style={{ textAlign: "center", marginBottom: "10px" }}
          message={error}
        />
      )}

      <span
        className={`${classes.listWrap_item} ${classes.listWrap_item__active}`}
        onClick={() => setIsCreateForm(!isCreateForm)}
      >
        {isCreateForm ? "Close" : "Create User"}
      </span>
      <div>
        {isCreateForm ? (
          <UserCreationForm onSuccess={loadUsers} />
        ) : (
          <div>
            {loading ? (
              <LoadingMessage styles={{ marginTop: "20px" }} />
            ) : (
              <>
                {users.length ? (
                  <div className={classes.userListTable_wrapper}>
                    <table className={classes.userListTable}>
                      <thead>
                        <tr className={classes.userListTable_header}>
                          <th>Username</th>
                          <th>Email</th>
                          <th className={classes.userListTable_hideMobile}>
                            Full Name
                          </th>
                          <th>Role</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user) => (
                          <tr key={user.id}>
                            <td>{user.username || "N/A"}</td>
                            <td>{user.email || "N/A"}</td>
                            <td className={classes.userListTable_hideMobile}>
                              {user.full_name}
                            </td>
                            <td>{ROLES[user.user_type]}</td>
                            <td>
                              <span className={classes.userListTable_iconBlock}>
                                <img
                                  className={
                                    classes.userListTable_iconBlock_icon
                                  }
                                  src={editIcon}
                                  alt="editIcon"
                                  onClick={() => handleEditUser(user)}
                                />
                                <img
                                  className={
                                    classes.userListTable_iconBlock_icon
                                  }
                                  src={deleteIcon}
                                  alt="deleteIcon"
                                  onClick={() => handleDeleteUser(user.id)}
                                />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <ErrorMessage
                    style={{ textAlign: "center" }}
                    message="You don't have any users in your company."
                  />
                )}
              </>
            )}
            <div>{/* Pagination goes here */}</div>
          </div>
        )}
      </div>
      {isEditingUser && (
        <UserRoleUpdate
          loadUsers={loadUsers}
          isOpen={isEditingUser}
          onClose={() => setIsEditingUser(false)}
          userObj={editingUserObj}
        />
      )}
    </div>
  );

}


export default AccountAccess