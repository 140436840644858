import React, { useEffect, useState } from "react";
import intentwiseIcon from "../../assets/icons/intentwise.png";
import amazonIcon from "../../assets/icons/amazon.png"
import SettingsServiceItem from "../../components/settings/SettingsServiceItem";
import { Link } from 'react-router-dom'
import classes from "./styles.module.scss";
import {
  get_intentwise_access_page,
  get_amazon_seller_central_access_page
} from "../../Routes/urls";
import { createUUID } from '../../utils'
import { useDispatch, useSelector } from "react-redux";
import { loadIntentwiseAuthStatus } from "../../store/actions/auth/intentwiseAuthActions";
import { loadAmazonSellerCentralAuthStatus } from "../../store/actions/auth/amazonSellerCentralAuthActions";


const DataSourceConnect = () => {
  const dispatch = useDispatch();

  const intentwise = useSelector((state) => state.intentwise);
  const amazonSellerCentral = useSelector((state) => state.amazonSellerCentral);
  const auth = useSelector(state => state.auth)
  const [selectedBrandID, setSelectedBrandID] = useState("")
  const [selectedBrand, setSelectedBrand] = useState(null)

  useEffect(() => {
    const brand = auth.user?.brands?.find(b => +b.id === +selectedBrandID)
    if (brand) {
      setSelectedBrand(brand)
    }
    else {
      setSelectedBrand(null)
    }
    // eslint-disable-next-line
  }, [selectedBrandID])

  useEffect(() => {
    if (selectedBrand?.data_sources?.map(d => d.name)?.includes("INTENTWISE")) {
      dispatch(loadIntentwiseAuthStatus(selectedBrandID));
    }
    if (selectedBrand?.data_sources?.map(d => d.name)?.includes("AMAZON_SELLER_CENTRAL")) {
      dispatch(loadAmazonSellerCentralAuthStatus(selectedBrandID));
    }

    //eslint-disable-next-line
  }, [selectedBrand]);

  return (
    <>
      <div className={classes.setting_connectForm}>
        <h2 className={classes.setting_connectForm__header}>
          Connect with Data Sources
        </h2>
        <select value={selectedBrandID} onChange={(e) => setSelectedBrandID(e.target.value)} className={classes.inputContainer_input}>
          <option>Select Brand</option>
          {auth?.user?.brands?.map(brand => (
            <option key={createUUID()} value={brand.id}>{brand.name}</option>
          ))}
        </select>
        {selectedBrand && selectedBrand?.data_sources?.length ? (
          <>
            {selectedBrand?.data_sources?.map(d => d.name)?.includes("INTENTWISE") && (
              <SettingsServiceItem
                settingsLink={get_intentwise_access_page(selectedBrandID)}
                connectLink={get_intentwise_access_page(selectedBrandID)}
                title="Intentwise:"
                icon={intentwiseIcon}
                connected={intentwise.connected}
                accountSelected={true}
                loading={false}
              />
            )}
            {selectedBrand?.data_sources?.map(d => d.name)?.includes("AMAZON_SELLER_CENTRAL") && (
              <SettingsServiceItem
                settingsLink={get_amazon_seller_central_access_page(selectedBrandID)}
                connectLink={get_amazon_seller_central_access_page(selectedBrandID)}
                title="Amazon Seller Central:"
                icon={amazonIcon}
                connected={amazonSellerCentral.connected}
                accountSelected={true}
                loading={false}
              />
            )}
          </>
        ) : (
          <h4 style={{ color: "rgb(230, 30, 20)" }}>
            You don't have access to any data sources!
          </h4>
        )}
      </div>
      <div className={classes.footerSettings}>
        <div className={classes.rightAligner}>
          <Link to="/">
            <button
              className={classes.buttonDash}
            >
              View My Dash
            </button>
          </Link>
        </div>
      </div>
    </>
  )

}


export default DataSourceConnect