export const TEMPLATE_LISTING_PAGE = "/"
export const SETTINGS_PAGE = "/settings"
export const TEMPLATE_PAGE = "/templates/:templateId/:brandId";

export const INTENTWISE_ACCESS_PAGE = "/access/:brandID/intentwise"
export const AMAZON_SELLER_CENTRAL_ACCESS_PAGE = "/access/:brandID/amazon-seller-central"

export const AMAZON_SELLER_CENTRAL_REDIRECT_PAGE = "/redirect/amazon-seller-central/"


export const LOGIN_PAGE = "/login"
export const PASSWORD_RESET_PAGE = "/forget-password/reset/:uid/:token"
export const ACCESS_DENIED_PAGE = "/access/denied"

export const get_intentwise_access_page = (brandId) => {
  return INTENTWISE_ACCESS_PAGE.replace(":brandID", brandId)
}

export const get_amazon_seller_central_access_page = (brandId) => {
  return AMAZON_SELLER_CENTRAL_ACCESS_PAGE.replace(":brandID", brandId)
}

export const get_template_page_url = (templateId, brandId) => {
  return TEMPLATE_PAGE.replace(":brandId", brandId).replace(":templateId", templateId)
}