import * as Types from "../actionTypes";
import axios from "../../../utils/axios";
import { getHeaders } from "../../../utils";

export const loadChartsData = (dateFilterData, chartNumber) => (dispatch, getState) => {
	const method = dateFilterData ? "post" : "get";

  const activeTemplate = getState().template.activeTemplate;
  const activeBrand = getState().template.activeBrand;
  const dateRange = getState().dateRange;

  if (dateRange.start && dateRange.end && dateFilterData) {
    dateFilterData.start = dateRange.start;
    dateFilterData.end = dateRange.end;
  }

  let endNumber = 13;

  const start = chartNumber || 6;
  const end = chartNumber ? chartNumber + 1 : endNumber;

  // get all charts data or only numbered chart data
  for (let i = start; i < end; i++) {
    dispatch({
      type: Types.chartActionTypes[`CHART_${i}_DATA_LOADING`],
      payload: true,
    });
    axios({
      method: method,
      url: `/data/template-${activeTemplate}/${activeBrand}/chart-${i}-data/`,
      data: dateFilterData,
      headers: getHeaders(),
    })
      .then((res) => {
        dispatch({
          type: Types.chartActionTypes[`CHART_${i}_DATA_LOADED`],
          payload: res.data,
        });
        dispatch({
          type: Types.chartActionTypes[`CHART_${i}_DATA_LOADING`],
          payload: false,
        });
      })
      .catch((error) => {
        let errorMessage = "Failed to load data";
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message || errorMessage;
        }
        dispatch({
          type: Types.chartActionTypes[`CHART_${i}_DATA_LOAD_ERROR`],
          payload: errorMessage,
        });
        dispatch({
          type: Types.chartActionTypes[`CHART_${i}_DATA_LOADING`],
          payload: false,
        });
      });
  }
};
