import React, { useState } from "react";
import classes from "./styles.module.scss";
import apiServices from "../apiServices";
import Select from "react-select";
import { ROLES } from "../../../../utils/authUtils";
import ErrorMessage from "../../../../components/FormElements/ErrorMessage";
import { useSelector } from "react-redux";

const UserRoleUpdate = ({ isOpen, userObj, loadUsers, onClose }) => {
  const user_brands = useSelector((state) => state?.auth?.user?.brands);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");
  const [loading, setLoading] = useState(false);
  const [user_type, setUserType] = useState({
    value: userObj.user_type,
    label: ROLES[userObj.user_type],
  });
  const [brands, setBrands] = useState(
    userObj?.brands?.map((brand) => ({ value: brand.id, label: brand.name }))
  );

  const submitHandler = (e) => {
    setLoading(true);
    setMessage("");
    e.preventDefault();
    apiServices
      .editUser(userObj.id, {
        user_type: user_type.value,
        brands: brands.map((brand) => brand.value),
      })
      .then((res) => {
        setMessage(res.data.message);
        setMessageType("success");
        setLoading(false);

        // update the users list
        loadUsers();
      })
      .catch((error) => {
        const errorMsg =
          error.response?.data?.message || "Something went wrong!";
        setLoading(false);
        setMessage(errorMsg);
        setMessageType("error");
      });
  };

  return (
    <>
      {isOpen && (
        <div className={classes.popupWrapper}>
          <div className={classes.popup}>
            <h2 className={classes.popup_header}>Edit User Role</h2>

            <form className={classes.popup_form} onSubmit={submitHandler}>
              <div className={classes.inputWrapper}>
                <label htmlFor="role">Select Role</label>
                <Select
                  defaultValue={user_type}
                  placeholder="Select Role"
                  id="role"
                  onChange={(value) => setUserType(value)}
                  options={Object.keys(ROLES).map((roleKey) => ({
                    value: roleKey,
                    label: ROLES[roleKey],
                  }))}
                />
              </div>

              <div className={classes.inputWrapper}>
                <label htmlFor="role">Select Brand(s)</label>
                <Select
                  defaultValue={brands}
                  isMulti
                  placeholder="Select Brand(s)"
                  onChange={(values) => setBrands(values)}
                  options={user_brands?.map((brand) => ({
                    value: brand.id,
                    label: brand.name,
                  }))}
                />
              </div>
              <div className={classes.popup_buttons}>
                <button
                  disabled={loading}
                  type="submit"
                  className={classes.popup_buttons__submit}
                >
                  Save
                </button>
                <span
                  className={classes.popup_buttons__cancel}
                  onClick={() => onClose && onClose()}
                >
                  Close
                </span>
              </div>
              {message && (
                <div style={{ marginTop: "5px" }}>
                  {messageType === "success" ? (
                    <div className={classes.formSuccess}>{message}</div>
                  ) : (
                    <ErrorMessage
                      style={{ textAlign: "center" }}
                      message={message}
                    />
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UserRoleUpdate;
