import React from "react";
import styles from "./styles.module.scss";
import gearIcon from "../../assets/images/gear.svg";
import gearDarkIcon from "../../assets/images/gearDark.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingMessage from "../helpers/LoadingMessage";
import { useSelector } from "react-redux";


const SettingsServiceItem = (props) => {
  const { icon, 
          title,
          connectLink, 
          connected, 
          loading, 
          settingsLink, 
          accountSelected, 
          accountSelectWarningText,
        } = props;
  const isNightMode = useSelector((state) => state?.theme?.nightMode);
  return (
    <div className={`${styles.box} ${isNightMode && styles.box_nightMode}`}>
      <div className={styles.titleWrapper}>
        <img alt="title" src={icon} className={styles.titleWrapper_img} />
        <p className={styles.titleWrapper_title}>{title}</p>
      </div>

      {loading ? (
        <span className={styles.box_loading}>
          <LoadingMessage />
        </span>
      ) : (
        <>
          {connected ? (
            <div className={styles.connetionSetting}>
              {!accountSelected && (
                <div className={styles.tooltip}>
                  <div className={styles.accountNotSelected}>
                    <FontAwesomeIcon
                      className={styles.accountNotSelected_icon}
                      icon={faExclamationCircle}
                    />
                  </div>
                  <span className={styles.tooltiptext}>{accountSelectWarningText}</span>
                </div>
              )}

              <div className={styles.connected}>
                <FontAwesomeIcon
                  className={styles.connectIcon}
                  icon={faCheckCircle}
                />
                Connected
              </div>
              
              <Link to={settingsLink} className={styles.connetionSetting_gear}>
                <img
                  alt="gearicon"
                  src={isNightMode ? gearDarkIcon : gearIcon}
                />
              </Link>
            </div>
          ) : (
            <>
              {connectLink?.startsWith("http") ? (
                <button
                  className={styles.connectButton}
                  onClick={() => (window.location = connectLink)}
                >
                  Connect
                </button>
              ) : (
                <Link className={styles.connectButton} to={connectLink}>
                  Connect
                </Link>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SettingsServiceItem;
