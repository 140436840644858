import axios from "../../../utils/axios";
import { getHeaders } from "../../../utils";
import * as Types from "../actionTypes";

export const loadNuggetsData =
  (dateFilterData, nuggetNumber) => (dispatch, getState) => {
    const method = dateFilterData ? "post" : "get";

    const activeTemplate = getState().template.activeTemplate;
    const activeBrand = getState().template.activeBrand;
    const dateRange = getState().dateRange;

    if (dateRange.start && dateRange.end && dateFilterData) {
      dateFilterData.start = dateRange.start;
      dateFilterData.end = dateRange.end;
    }

    const start = nuggetNumber || 1;
    const end = nuggetNumber ? nuggetNumber + 1 : 6;

    // get all 4 nuggets data
    for (let i = start; i < end; i++) {
      dispatch({
        type: Types.nuggetActionTypes[`NUGGET_${i}_DATA_LOADING`],
        payload: true,
      });
      axios({
        method: method,
        url: `/data/template-${activeTemplate}/${activeBrand}/nugget-${i}-data/`,
        data: dateFilterData,
        headers: getHeaders(),
      })
        .then((res) => {
          dispatch({
            type: Types.nuggetActionTypes[`NUGGET_${i}_DATA_LOADED`],
            payload: res.data,
          });
          dispatch({
            type: Types.nuggetActionTypes[`NUGGET_${i}_DATA_LOADING`],
            payload: false,
          });
        })
        .catch((error) => {
          let errorMessage = "Failed to load data";
          if (error.response && error.response.data) {
            errorMessage = error.response.data.message || errorMessage;
          }
          dispatch({
            type: Types.nuggetActionTypes[`NUGGET_${i}_DATA_LOAD_ERROR`],
            payload: errorMessage,
          });
          dispatch({
            type: Types.nuggetActionTypes[`NUGGET_${i}_DATA_LOADING`],
            payload: false,
          });
        });
    }
  };
