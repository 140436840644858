import React from "react";
import classes from "./styles.module.scss";
import Sidebar from "./sidebar/Sidebar";
import { useSelector } from "react-redux";

import Footer from "./Footer";

const LayoutContainer = ({ children }) => {
  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  return (
    <div
      className={`${classes.dashboard} ${
        isNightMode && classes.dashboard_nightMode
      }`}
    >
      <div className={classes.dashboard_main} id="dashboard">
        <div className={classes.dashboard_main_top}>{children}</div>
        <div className={classes.dashboard_main_bottom}>
          <Footer />
        </div>
      </div>

      <Sidebar />
    </div>
  );
};

export default LayoutContainer;
