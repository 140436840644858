import React from "react";
import classes from "./styles.module.scss";
import image404 from "../../assets/images/404.png";
import { setPageTitle } from "../../utils";
import { useSelector } from "react-redux";

const NotFound = ({ history }) => {
  setPageTitle("Page not found");

  const isNightMode = useSelector((state) => state?.theme?.nightMode);

  return (
    <div
      className={`${classes.container} ${
        isNightMode && classes.container_nightMode
      }`}
    >
      <div className={classes.container_box}>
        <img src={image404} alt="404" />
        <h1>404 - Page not found</h1>
        <button onClick={() => history.push("/")}>Go Home</button>
      </div>
    </div>
  );
};

export default NotFound;
