import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFound from "../pages/Others/NotFound";
import AccessDenied from "../pages/Others/AccessDenied";
import SettingsPage from "../pages/Settings/Settings";

import Intentwise from "../pages/external-access/Intentwise";
import AmazonSellerCentral from "../pages/external-access/AmazonSellerCentral";
import AmazonSellerCentralRedirect from "../pages/external-access/redirects/AmazonSellerCentralRedirect";

import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";

import * as URLS from "./urls";
import withSuspense from "../utils/withSuspense";
import AdminRoute from "./AdminRoute";

const TemplateListingPage = React.lazy(() =>
  import("../pages/TemplateListing/TemplateListingPage")
);

const TemplatePage = React.lazy(() =>
  import("../pages/TemplatePage/TemplatePage")
);
const LoginPage = React.lazy(() => import("../pages/auth/LoginPage"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));


const Routes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={URLS.TEMPLATE_LISTING_PAGE}
        exact
        component={withSuspense(TemplateListingPage)}
      />
      <PrivateRoute
        path={URLS.TEMPLATE_PAGE}
        exact
        component={withSuspense(TemplatePage)}
      />
      <PrivateRoute path={URLS.SETTINGS_PAGE} exact component={SettingsPage} />

      {/* data sources */}
      <AdminRoute
        exact
        path={URLS.INTENTWISE_ACCESS_PAGE}
        component={Intentwise}
      />

      <AdminRoute
        exact
        path={URLS.AMAZON_SELLER_CENTRAL_ACCESS_PAGE}
        component={AmazonSellerCentral}
      />

      <AdminRoute
        exact
        path={URLS.AMAZON_SELLER_CENTRAL_REDIRECT_PAGE}
        component={AmazonSellerCentralRedirect}
      />

      {/* auth routes */}
      <GuestRoute
        exact
        path={URLS.LOGIN_PAGE}
        component={withSuspense(LoginPage)}
      />
      <Route
        exact
        path={URLS.PASSWORD_RESET_PAGE}
        component={withSuspense(ResetPassword)}
      />
      <Route path={URLS.ACCESS_DENIED_PAGE} component={AccessDenied} />
      <Route component={NotFound} />

    </Switch>
  );
};

export default Routes;
