import * as Types from "../../actions/actionTypes";

const initialState = {};

for (let i = 1; i < 5; i++) {
  initialState["nugget" + i] = {
    data: {
      chart_info: {
        chart_label: "",
        data: {},
      },
    },
    loading: true,
    error: "",
  };
}

function amazonNuggetReducer(state = initialState, action) {
  const newState = { ...state };
  for (let i = 1; i < 6; i++) {
    let nuggetNumber = "nugget" + i;

    if (action.type === Types.amazonNuggetActionTypes[`AMAZON_NUGGET_${i}_DATA_LOADING`]) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        loading: action.payload,
      };
      return newState;
    }

    if (action.type === Types.amazonNuggetActionTypes[`AMAZON_NUGGET_${i}_DATA_LOADED`]) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        data: action.payload,
        error: "",
      };
      return newState;
    }
    if (
      action.type === Types.amazonNuggetActionTypes[`AMAZON_NUGGET_${i}_DATA_LOAD_ERROR`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        error: action.payload,
      };
      return newState;
    }
  }

  if (action.type === Types.AMAZON_RESET_CHART_DATA) {
    return initialState;
  }
  return state;
}

export default amazonNuggetReducer;
