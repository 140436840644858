import React, { useState } from "react";
import classes from "./styles.module.scss";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../../assets/images/Dash Icon_Social.png";
import userIcon from "../../../assets/images/sidebar/user.svg";
import homeIcon from "../../../assets/images/sidebar/home.svg";
import logoutIcon from "../../../assets/images/sidebar/logout.svg";
import calenderIcon from "../../../assets/images/sidebar/calender.svg";
import downloadIcon from "../../../assets/images/sidebar/download.svg";
import { NavLink } from "react-router-dom";
import { logout } from "../../../store/actions/auth/authActions";
import DateSelectorSidebar from "../../DateSelector/DateSelectorSidebar";
import { loadNuggetsData } from "../../../store/actions/chartData/nuggetActions";
import { loadChartsData } from "../../../store/actions/chartData/chartActions";
import ExportDialog from "./ExportDialog";
import { SET_DATE_RANGE } from "../../../store/actions/actionTypes";
import { SETTINGS_PAGE } from "../../../Routes/urls";

const Sidebar = ({ history }) => {
  const dispatch = useDispatch();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);

  const onDateRangeSelect = (value) => {
    const start = value.start.format("YYYY-MM-DD");
    const end = value.end.format("YYYY-MM-DD");

    dispatch({ type: SET_DATE_RANGE, payload: { start, end } });
    dispatch(loadNuggetsData({ start, end }));
    dispatch(loadChartsData({ start, end }));
    setShowDatePicker(false);
  };

  const exportDialogHandler = () => {
    setShowExportDialog((prev) => {
      if (!prev) {
        // if previously it was not opened, means it'll now open,
        // so close the date picker if it's open
        setShowDatePicker(false);
      }
      return !prev;
    });
  };
  const datePickerHandler = () => {
    setShowDatePicker((prev) => {
      if (!prev) {
        // if previously it was not opened, means it'll now open,
        // so close the export dialog if it's open
        setShowExportDialog(false);
      }
      return !prev;
    });
  };

  return (
    <div className={classes.sideBar} id="sidebar">
      <div className={classes.sideBar_container}>
        <div className={classes.sideBar_container__top}>
          <NavLink
            to="/"
            className={`${classes.sideBar_icon} ${classes.sideBar_icon__home}`}
          >
            <img src={homeIcon} alt="homeIcon" />
          </NavLink>
          <NavLink to={SETTINGS_PAGE} className={classes.sideBar_icon}>
            <img alt="settings" src={userIcon} />
          </NavLink>
          {/* Show export and date range picker only on template page */}
          {window.location.pathname.includes("/templates/") && (
            <>
              <div
                className={classes.sideBar_icon}
                onClick={exportDialogHandler}
              >
                <img
                  src={downloadIcon}
                  alt="export"
                />
              </div>
              {showExportDialog && (
                <ExportDialog close={() => setShowExportDialog(false)} />
              )}

              <div className={classes.sideBar_icon} onClick={datePickerHandler}>
                <img src={calenderIcon} alt="date range" />
              </div>
              {showDatePicker && (
                <DateSelectorSidebar
                  onDateSelect={onDateRangeSelect}
                  close={() => setShowDatePicker(false)}
                />
              )}
            </>
          )}
          <div
            className={classes.sideBar_icon}
            onClick={() => dispatch(logout(history))}
          >
            <img src={logoutIcon} alt="logoutIcon" />
          </div>

        </div>

        <img className={classes.sideBar_logo} alt="logo" src={logo} />
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
