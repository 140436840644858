import * as Types from '../actionTypes';
import axios from "../../../utils/axios";
import {getHeaders} from '../../../utils';
import { loadUserInfo } from './authActions';


export const loadIntentwiseAuthStatus = (brandId) => dispatch => {
  dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: true})
  // remove previous loaded accounts
  dispatch({ type: Types.INTENTWISE_ACCOUNTS_LOADED, payload: null });
  dispatch({ type: Types.INTENTWISE_SAVE_CREDENTIALS_LOAD_ERROR, payload: {} });
  
  axios.get(`/intentwise/auth/${brandId}/status/`, {headers: getHeaders()})
  .then(res => {
    dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADED, payload: res.data})
    dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: false})
  })
  .catch(err => {
    dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: false})
  })
}


export const loadIntentwiseAccounts = (data) => (dispatch) => {
  dispatch({ type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: true });
  dispatch({ type: Types.INTENTWISE_SAVE_CREDENTIALS_LOAD_ERROR, payload: {} });
  axios
    .post(`/intentwise/auth/list-accounts/`, data, { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.INTENTWISE_ACCOUNTS_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: Types.INTENTWISE_SAVE_CREDENTIALS_LOAD_ERROR,
        payload: err.response?.data || {},
      });
    })
    .finally(() => {
      dispatch({ type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: false });
    });
};


export const loadIntentwiseCredential = (brandId) => dispatch => {
  dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_SUCCESS, payload: false})
  dispatch({type: Types.INTENTWISE_CREDENTIALS_LOADING, payload: true})
  axios.get(`/intentwise/auth/${brandId}/credentials/`, {headers: getHeaders()})
  .then(res => {
    // console.log(res.data);
    dispatch({type: Types.INTENTWISE_CREDENTIALS_LOADED, payload: res.data})
    dispatch({type: Types.INTENTWISE_CREDENTIALS_LOADING, payload: false})
  })
  .catch(err => {
    // console.log(err.response);
    dispatch({type: Types.INTENTWISE_CREDENTIALS_LOADING, payload: false})
  })
}

export const updateIntentwiseCredential = (brandId, data) => dispatch => {
    dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_SUCCESS, payload: false})
    dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_LOADING, payload: true})
  axios.put(`/intentwise/auth/${brandId}/credentials/`, data, {headers: getHeaders()})
  .then(res => {
    // console.log(res.data);
    dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_LOADED, payload: res.data})
    dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_SUCCESS, payload: true})
    dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_LOADING, payload: false})
    dispatch(loadIntentwiseAuthStatus(brandId))
  })
  .catch(err => {
    // console.log(err.response);
    dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_LOADING, payload: false})
    dispatch({type: Types.INTENTWISE_SAVE_CREDENTIALS_LOAD_ERROR, payload: err.response?.data || {}})
  })
}

export const revokeIntentwiseAccess = (brandId) => dispatch => {
  dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: true})
  axios.get(`/intentwise/auth/${brandId}/revoke/`, {headers: getHeaders()})
  .then(res => {
    // console.log(res.data);
    dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADED, payload: res.data})
    dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: false})
    dispatch(loadIntentwiseAuthStatus(brandId))
    dispatch(loadIntentwiseCredential(brandId))
    dispatch(loadUserInfo())
  })
  .catch(err => {
    // console.log(err.response);
    dispatch({type: Types.INTENTWISE_AUTH_STATUS_LOADING, payload: false})
    dispatch(loadIntentwiseAuthStatus(brandId))
  })
}