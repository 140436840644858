import React, { useEffect, useState } from "react";
import { changePassword } from '../../store/actions/auth/authActions';

import classes from "./styles.module.scss";
import { useSelector, useDispatch } from 'react-redux'
import { CHANGE_PASSWORD_ERRORS } from "../../store/actions/actionTypes";

const ChangePassword = () => {

  const dispatch = useDispatch();

  const [old_password, setOldPassword] = useState('')
  const [new_password1, setNewPassword1] = useState('')
  const [new_password2, setNewPassword2] = useState('')

  const auth = useSelector((state) => state.auth);

  const submitHandler = e => {
    e.preventDefault()
    dispatch(changePassword({old_password, new_password1, new_password2}))
  }

  useEffect(() => {
    dispatch({type: CHANGE_PASSWORD_ERRORS, payload: {}})
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.setting_connectForm}>
      <h2 className={classes.setting_connectForm__header}>
        Change Password
      </h2>
      <form onSubmit={submitHandler}>
        <PasswordField
          label="Current Password"
          value={old_password}
          onChange={setOldPassword}
          error={auth.changePassword.errors.old_password}
        />
        <PasswordField
          label="New Password"
          value={new_password1}
          onChange={setNewPassword1}
          error={auth.changePassword.errors.new_password1}
        />
        <PasswordField
          label="Confirm Password"
          value={new_password2}
          onChange={setNewPassword2}
          error={auth.changePassword.errors.new_password2}
        />

        {auth.changePassword.success && (
          <div className={classes.inputContainer}>
            <div className={classes.inputContainer_successMessage}>
              Password changed successfully!
            </div>
          </div>
        )}

        <button disabled={auth.loading} className={classes.inputContainer_submitBtn} type='submit'>
          {auth.loading ? "Please wait..." : "Change Password"}
        </button>
      </form>
    </div>
  )

}


export default ChangePassword

const PasswordField = ({ label, value, onChange, error, ...rest }) => {
  return (
    <div className={classes.inputContainer}>
      <label className={classes.inputContainer_label}>
        {label}
      </label>
      <input
        type='password'
        className={classes.inputContainer_input}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />

      {!!error && (
        <div className={classes.inputContainer_errorMessage}>
          {error}
        </div>
      )}
      
    </div>
  )
}